<template>
    <action-button
        icon="outbound-link"
        @click="openCampaign(item)">
        Ad Account
    </action-button>
</template>

<script>
import ActionButton from '@/components/globals/ActionButton';
import { mapState, mapGetters } from 'vuex';

export default {
    name: 'AdAccountButton',
     components: {
        ActionButton,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
            required: true
        },
    },
    computed: {
        ...mapState({
            dateRange: (state) => state.metrics.dateRange
        }),
        ...mapGetters([
            'dealerFacebookAdAccountUrlFiltered'
        ]),
    },
    methods: {
        openCampaign(item) {
            const url = this.dealerFacebookAdAccountUrlFiltered({
                dateRange: this.dateRange,
                campaigns: [ item.id ]
            });
            window.open(url);
        }
    }
};
</script>
